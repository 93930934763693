const services = [
  {
    id: "1",
    name: "Process Engineering",
    serviceSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Process Engineering.",
    description:
      "Welcome to SEPREX, your trusted partner in process engineering services. At SEPREX, we specialize in providing tailored solutions that optimize your industrial processes. From concept to execution, our experienced team is committed to enhancing efficiency, reliability and sustainability. Explore our services and let's enhance your operations together. Welcome to a new era of process excellence with SEPREX. We provide the following services.",
    image: "/images/services/process_engg.jpg",
    serviceheading: "We provide the following services:",
    serviceslist: [
      "Feasibility Studies",
      "Piping and Instrumentation Diagram (P&ID) Development",
      "Basic Engineering Design Packages (BEP)",
      "Front End Engineering Design (FEED)",
      "Detailed Engineering Package",
      "Fluid Flow/Surge Analysis",
      "Troubleshooting and Root Cause Analysis",
      "Energy Efficiency Consulting",
      "Cost Estimation",
    ],
    color: "#ffffff",
  },
  {
    id: "2",
    name: "Process Simulation",
    serviceSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Process Simulation.",
    description:
      "Welcome to SEPREX, where the alchemy of virtual innovation changes the landscape of chemical processes. As experts in chemical process simulation, we enter a realm of possibilities that redefine your approach to industrial chemistry. At SEPREX, we don't just simulate; we catalyse a digital revolution, enabling you to imagine, optimize and innovate within the complex world of chemical engineering.",
    image: "/images/services/process_simulation2.jpg",
    serviceheading: "Industries We Serve:",
    serviceslist: [
      "Oil and Gas",
      "Petrochemicals",
      "Chemical Processing",
      "Water Treatment",
      "Power Generation",
      "Pharmaceuticals",
      "Food and Beverages",
      "And more..!",
    ],
    color: "#4c4c4c",
  },
  {
    id: "3",
    name: "CFD Simulation",
    serviceSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our CFD Simulation.",
    description:
      "Welcome to SEPREX, your gateway to a transformative world where fluid dynamics meets accuracy through computational fluid dynamics (CFD) simulation. At SEPREX, we are experts in unlocking the secrets of fluid behaviour, providing unique insights for industries ranging from aerospace and automotive to energy and environmental engineering. Join us on a journey where every flow, every interaction and every design is carefully analysed and optimized for peak performance.",
    image: "/images/services/cfdsimulation.jpg",
    serviceheading: "Industries we serve:",
    serviceslist: [
      "Manufacturing",
      "Chemical Processing",
      "Petrochemicals",
      "Pharmaceuticals",
      "Energy",
      "Environmental",
      "And more..!",
    ],
    color: "#ffffff",
    powerofCFDtitle: "Explore the Power of CFD Simulation with SEPREX:",
    powerofCFDHeadings: [
      "Fluid Dynamics Unleashed:",
      "Optimization beyond Boundaries:",
      "Energy Efficiency Redefined:",
    ],
    powerofCFDcontent: [
      "Immerse yourself in the world of fluid dynamics with SEPREX. Our CFD simulations offer a virtual laboratory where the behaviour of fluids and gases comes to life, providing a detailed understanding of flow patterns, heat transfer and pressure distribution.",
      "At SEPREX, we don't just simulate; we adapt. Our CFD simulations empower you to refine designs, troubleshoot problems, and gain unparalleled efficiency in your engineering processes. Experience customization that goes beyond the limitations of traditional methods.",
      "Harness the power of CFD for sustainable energy solutions. At SEPREX, our simulations optimize energy systems, improve heat exchanger performance, and increase overall efficiency, contributing to a greener and more sustainable future.",
    ],
    whychoose: "Why Choose SEPREX for CFD Simulation:",
    whychooseHeadings: [
      "Expertise beyond Expectations : ",
      "Innovative Solutions : ",
      "Cost-Efficient Design : ",
      "Engineering Excellence : ",
    ],
    whychooseContent: [
      " Benefit from the expertise of our experienced team skilled in the nuances of fluid dynamics and CFD simulation.",
      " Advance your organization with innovative solutions derived from detailed CFD analysis and optimization.",
      " Identify and correct design inefficiencies without the cost of physical prototyping, ensuring optimal resource utilization.",
      " Navigate the complexities of fluid dynamics with confidence, as SEPREX brings a commitment to engineering excellence to every simulation.\nWelcome to the realm where fluid dynamics meets precision engineering. Explore the transformative possibilities with SEPREX – your partner in unlocking the full potential of CFD simulation.",
    ],
    
  },
  {
    id: "4",
    name: "Process Intensification &\n troubleshooting",
    serviceSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Process Intensification & troubleshooting.",
    description:
      "Welcome to SEPREX, where simplicity meets process depth and efficiency in troubleshooting solutions. At the forefront of industrial innovation, we specialize in revolutionizing processes to achieve higher productivity, lower environmental impact and increased reliability. Whether you are looking to optimize existing operations or troubleshoot challenges, SEPREX is your dedicated partner in advancing your industrial endeavours.",
    image: "/images/services/processIntensification.jpg",
    serviceheading: "Industries we serve:",
    serviceslist: [
      "Manufacturing",
      "Chemical Processing",
      "Petrochemicals",
      "Pharmaceuticals",
      "Energy",
      "Environmental",
      "And more..!",
    ],
    color: "#4c4c4c",
    elevateTitle: "Elevate Your Processes with SEPREX:",
    elevateHeadings: [
      "Process Intensification Mastery:",
      "Troubleshooting Expertise:",
      "Innovation in Every Detail:",
      "Environmental Responsiveness:",
      "Proactive Problem Solving:",
    ],
    elevatecontent: [
      "Experience a paradigm shift in efficiency with SEPREX. Our process intensification solutions are engineered to enhance the performance of your operations, minimize resource consumption and maximize output.",
      "When challenges arise, SEPREX is your go-to source for expert troubleshooting. Our experienced team deeply understands the complexities of your processes, identifying and resolving issues with accuracy and efficiency.",
      "At SEPREX, we believe in innovation at every level. Our process intensification strategies and problem solving approaches are brimming with creativity, offering customized solutions tailored to your unique industrial needs.",
      "Sustainability is at the core of our solutions. Whether through process intensification or troubleshooting, SEPREX ensures that your operations are in line with environmental standards, contributing to a greener and more responsible industrial footprint.",
      "Stay ahead of challenges with SEPREX. Our proactive troubleshooting methods anticipate problems before they escalate, providing quick, informed solutions that keep your processes running smoothly.",
    ],
    whychoose:
      "Why Choose SEPREX for Process Intensification and Troubleshooting:",
    whychooseHeadings: [
      "Specialized Expertise:",
      "Innovative Solutions:",
      "Reliability and Efficiency:",
      "Customized Approaches:",
    ],
    whychooseContent: [
      "Benefit from our specialized knowledge in process intensification techniques and troubleshooting methods.",
      "Drive your operations forward with innovative strategies tailored to your specific industry and challenges.",
      "Trust SEPREX for solutions that increase the reliability and efficiency of your processes, ensuring lasting success.",
      "Take a tailored approach that addresses the unique aspects of your operation, optimizing for efficiency and productivity.Welcome to a future where your industrial processes not only meet but exceed expectations. Explore the possibilities with SEPREX – your partner in process intensification and troubleshooting excellence.",
    ],
    thirdImage: "/images/services/processintensteps.jpg",
  },
];

export default services;
