const products = [
  {
    id: "1",
    name: "Lab/Bench/Pilot Plants",
    productSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Pilot Plants.",
    description: `Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost.
    Welcome to SEPREX, where innovation takes flight through our state-of-the-art pilot plants! We specialize in designing and delivering state-of-the-art pilot plants that serve as launch pads for your ground-breaking ideas and projects. The important elements of a good lab/bench/pilot plant are flexibility in quickly changing or adding accessories/equipment and safety. SEPREX has the experience and expertise to build safe, flexible and adaptable lab/bench/pilot plants. The SEPREX team works with customers from the outset to understand their objectives, which are used to size, design, construct and operate the unit with the appropriate level of automation and control. Experienced technicians ensure that the plant will run over a range of real process conditions to meet customer expectations.`,
    image: "/images/products/modular_plants.jpg",
    color: "#7BD3EA",
    sectiontitle: "Embark on Innovation with Our Pilot Plants:",
    featuresTitle: [
      "R&D Excellence",
      "Customized Solutions",
      "Advanced Technology",
      "Scalability",
      "Precision and Accuracy",
      "Efficient Project Development",
    ],
    features: [
      "At SEPREX, we understand the important role of research and development in shaping the future. Our pilot plants are carefully designed to facilitate experimentation, testing and innovation, providing you with the perfect environment to refine your processes.",
      "Every project is unique, and our solutions are also unique. We work closely with you to design pilot plants that meet your specific requirements, ensuring a seamless transition from concept to reality.",
      "What sets us apart is our commitment to staying at the forefront of technological advancements. Our pilot plants are equipped with the latest technologies, allowing you to explore and validate new ideas with confidence.",
      "Thinking big? Our pilot plants are designed to be scalable, providing the flexibility to adapt and grow as your project evolves. This ensures that your initial concepts can seamlessly transition to full-scale production.",
      "Achieve precision in your experiments with our pilot plants. We prioritize accuracy in measurement and control systems to provide you with reliable data to make informed decisions.",
      "Time is of utmost importance in research and development. Our pilot plants are engineered to increase efficiency, accelerate project timelines and enable you to bring your innovations to market faster.",
    ],
    industriesData: [
      "Pharmaceuticals",
      "Chemical Processing",
      "Biotechnology",
      "Food and Beverage",
      "Environmental Sciences",
      "Renewable Energy",
      "Materials Science",
      "And more..!",
    ],
    fourthPartTitle: "Our Promise",
    fourthPartImage: "/images/products/pilotplantfeatures2.png",
    fourthPartContent:
      "At SEPREX, we are dedicated to fostering a culture of innovation. Our team of experts is committed to supporting your journey from concept to commercialization, providing the tools and expertise needed to turn your ideas into reality.Visit our website to discover the full range of pilot plant solutions we offer and learn how SEPREX can be your trusted partner in pushing the boundaries of the possible. Thank you for choosing us to be a part of your innovation journey.",
  },
  {
    id: "2",
    name: "Modular/Skid-Mounted Plants",
    productSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Skid Mounted Plants.",
    description: `We specialize in providing innovative, efficient and cost effective solutions to meet your industrial requirements. Our modular and skid-mounted plants are designed to revolutionize the way you execute projects, providing flexibility, scalability and rapid deployment.`,
    image: "/images/products/modular_plants.jpg",
    color: "#7BD3EA",
    sectiontitle: "Why Choose Our Modular/Skid-Mounted Plants?",
    featuresTitle: [
      "Efficiency Redefined",
      "Tailored Solutions",
      "Quality Assurance",
      "Rapid Deployment",
      "Flexibility & Scalability",
      "Cost-Effective Solutions",
    ],
    features: [
      "Our plants are engineered for optimum efficiency, streamlining your processes and minimizing downtime. Experience seamless integration and start-up, allowing you to focus on what matters most – your business.",
      "No two projects are alike and neither are our solutions. We work closely with you to understand your specific needs, customizing modular and skid-mounted plants that perfectly match your operational needs.",
      "At SEPREX, quality is our top priority. Our plants are built to the highest industry standards, incorporating cutting-edge technologies and materials to ensure reliability and longevity.",
      "Time is money. Our modular and skid-mounted approach accelerates project timelines, enabling you to meet deadlines and get a faster return on your investment.",
      "Adaptability is key in the dynamic world of industry. Our plants are designed with flexibility in mind, allowing for easy modification and expansion as your business grows.",
      "We understand the importance of cost efficiency. Our modular and skid-mounted plants provide a cost-effective alternative to traditional construction methods, reducing overall project expenses.",
    ],
    industriesData: [
      "Oil and Gas",
      "Petrochemicals",
      "Chemical Processing",
      "Water Treatment",
      "Power Generation",
      "Pharmaceuticals",
      "Food and Beverages",
      "And more..!",
    ],
    fourthPartTitle: "Our Commitment",
    fourthPartContent:
      "At SEPREX, we are committed to exceeding your expectations. Our experienced team of engineers, designers and project managers is dedicated to delivering turnkey solutions that improve your operational performance.Check out our website to discover the full range of modular and skid-mounted plants we offer and learn more about how we can your project into a success story. Thank you for considering SEPREX as your trusted partner in modular plant solutions.",
  },
  {
    id: "3",
    name: "Process Equipment",
    productSingleSentence: "Get to market faster with your technology with SEPREX without compromising safety and quality at optimal cost using our Process Equipment Plants.",
    description: `Welcome to SEPREX, an emerging force that is ready to redefine excellence in process equipment solutions. As a growing entity, we have embarked on a journey of setting new standards in accuracy, reliability and innovation, committed to becoming a trusted partner for industries seeking cutting-edge solutions.`,
    image: "/images/products/process_equiment.jpg",
    color: "#7BD3EA",
    sectiontitle: "Embarking on Excellence in Process Equipment:",
    featuresTitle: [
      "Innovative Technological Frontiers",
      "Tailor-Made Solutions",
      "Commitment to Reliability and Durability",
      "Diverse and Comprehensive Portfolio",
      "Emerging Engineering Prowess",
      "Local Roots, Global Vision",
      "Distillation Column",
      "Reactors",
      "Heat Exchanger",
    ],
    features: [
      "At SEPREX, our ethos is based on pioneering technological advancements. Our growing range of process equipment has been thoughtfully designed to incorporate state-of-the-art features, ensuring not only efficiency but also sustainability, while adhering to the highest industry standards.",
      "Recognizing the specific needs of each enterprise, we foster collaborative relationships with our customers. This collaborative approach allows us to offer specialized process equipment solutions, tailored to the specific needs and specifications of our valued partners.",
      "Trust is the cornerstone of our foundation. As a budding entity, our process equipment is engineered with meticulous care with emphasis on reliability and durability to ensure consistent performance in diverse and challenging environments.",
      "Our growing portfolio spans multiple industries including pharmaceuticals, chemicals, petrochemicals and others. From basic reactors to complex heat exchangers and distillation columns, our extended range is designed to meet the diverse requirements of our customers.",
      "Guided by a dynamic team of emerging engineers, our commitment goes beyond traditional functionality. We strive to bring innovation to every aspect of our process equipment solutions, aspiring to set new standards and surpass prevailing industry standards.",
      "As SEPREX takes its first steps; we combine global aspirations with local dedication. Our personalized service and unwavering support transcends geographical boundaries, providing steadfast support to our customers regardless of their location.",
      "Welcome to SEPREX, where expertise meets innovation in distillation solutions. As a newcomer, we are driven by innovation and committed to redefining the standards of distillation technology. Our focus is on providing cutting-edge solutions designed to meet the growing needs of industries across the globe. Visit our site to learn how SEPREX is set to make a lasting impact in the world of distillation. Join us on this exciting journey where cutting-edge technology meets a new perspective, shaping the future of liquid separation processes. Your introduction to the future of distilling begins here.",
      "At SEPREX, we drive innovation in the world of reactor technology. Our reactors are not just products; they are solutions designed with a deep understanding of real-world demands. Whether its optimizing processes in [specific industries] or prioritizing sustainability, our reactors deliver a tangible impact. Join us in reshaping the industrial landscape with practical, real-world solutions that redefine what is possible in reactor technology.",
      "Step into the future of thermal excellence with SEPREX. As the industry leader in heat exchange solutions, we bring innovation and efficiency to the forefront. Join us in optimizing performance and reliability through our advanced heat exchangers. Welcome to a world where precision meets progress.",
    ],
    industriesData: [
      "Pharmaceuticals",
      "Chemical Processing",
      "Biotechnology",
      "Food and Beverage",
      "Environmental Sciences",
      "Renewable Energy",
      "Materials Science",
      "And more..!",
    ],
    fourthPartTitle: "Our Aspiration",
    fourthPartContent:
      "At SEPREX, we aspire to be the catalyst for your success. Our commitment to quality, innovation and customer satisfaction defines our journey. Explore our growing portfolio on our website, where you will see the promising spectrum of process equipment solutions we offer. Thank you for considering SEPREX as your partner in leading process equipment solutions. Embrace a future defined by efficiency and performance as we embark on this exciting journey together!",
  },
];

export default products;
